import { useState } from "react"
import durness from '../assets/img/destinations/durness.png'
import edynburgh from '../assets/img/destinations/edynburgh.png'
import tromso from '../assets/img/destinations/tromso.png'
import manchester from '../assets/img/destinations/manchester.png'
import nessebar from '../assets/img/destinations/nessebar.png'
import mdina from '../assets/img/destinations/mdina.png'
import wieden from '../assets/img/destinations/wieden.png'
import rzym from '../assets/img/destinations/rzym.png'
import rejkiavik from '../assets/img/destinations/rejkiavik.png'
import londyn from '../assets/img/destinations/londyn.png'
import poronin from '../assets/img/destinations/poronin.png'

type Table = {
  tableName: string,
  seats: string[]
  img: string
}

const TABLES: Table[] = [
  {
    tableName: 'Tromso',
    seats: ['25A', '25B', '25C', '25D', '25E'],
    img: tromso
  },
  {
    tableName: 'Manchester',
    seats: ['19B', '19C', '19D', '20E', '20F', '21E', '21F', '18A', '18B', '18C', '18D'],
    img: manchester
  },
  {
    tableName: 'Nessebar',
    seats: ['11A', '11B', '3C', '3D', '10E', '10F', '12A', '12B', '12C', '12D', '26C', '26D'],
    img: nessebar
  },
  {
    tableName: 'Mdina',
    seats: ['23A', '23B', '17E', '1A', '1B', '6E', '8E', '8F'],
    img: mdina
  },
  {
    tableName: 'Wiedeń',
    seats: ['4E', '4F', '2E', '2D', '3F', '3E', '9A', '9C', '9E', '10C', '10D'],
    img: wieden
  },
  {
    tableName: 'Durres',
    seats: ['1C', '1D', '1F', '1E', '4A', '4B', '5A', '5B'],
    img: durness
  },
  {
    tableName: 'Edynburgh',
    seats: ['2A', '2B', '2C', '5E', '7A', '7B', '8A', '8B'],
    img: edynburgh
  },
  {
    tableName: 'Rzym',
    seats: ['20A', '20B', '20C', '20D', '21A', '21B', '27A', '19E', '19F', '23C', '23D'],
    img: rzym
  },
  {
    tableName: 'Rejkiawik',
    seats: ['13A', '13B', '13E', '14D', '14E', '14F', '16A', '16B', '15D', '15E'],
    img: rejkiavik
  },
  {
    tableName: 'Londyn',
    seats: ['11C', '11D', '11E', '13D', '13C', '14B', '14A', '15A', '15B', '16C', '16D', '26E'],
    img: londyn
  },
  {
    tableName: 'Poronin',
    seats: ['17F', '19A', '18E', '21D', '22F'],
    img: poronin
  }
]

export const Tables = () => {
  const [selectedSeat, setSelectedSeat] = useState('')
  const [table, setTable] = useState<Table>()
  const isSeatSelected = selectedSeat.length

  const handleSeatSelection = (selectedSeat: string) => setSelectedSeat(selectedSeat)
  const findDestination = () => {
    const table = TABLES.find(table => table.seats.find(seatNumber => seatNumber.toLowerCase() === selectedSeat.toLowerCase()))
    setTable(table)
    setSelectedSeat('')
  }

  return (
    <div className='text-center py-[30px] sm:py-[50px] xl:container mx-auto relative'>
    <h2 className='text-4xl font-playfairDisplay mb-6 sm:mb-12 font-bold'>Mam już miejsce, więc dokąd lecę?</h2>
    <p className='font-quicksand'>
      Podaj numer miejsca!
    </p>
      <div className='flex flex-col items-center mt-10'>
        <input
          type="text"
          name="name"
          value={selectedSeat}
          className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-quicksand sm:w-[400px]"
          placeholder="Tutaj wpisz swój numer miejsca"
          onChange={(event) => handleSeatSelection(event.target.value)}
        />
        <button
          type="submit"
          className={`${!isSeatSelected ? 'bg-gray-700' : 'bg-darkRed hover:bg-red-700'}  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-5 font-quicksand w-[250px]`}
          disabled={!isSeatSelected}
          onClick={findDestination}
        >
          POWIEDZ MI GDZIE LECĘ!
        </button>
        {table && (
          <div className='sm:w-[400px] mt-10'>
            <img src={table.img} alt="destination"/>
          </div>
        )}
      </div>
    </div>
  )
}