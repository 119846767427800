import { Card } from './Card';
import church from '../assets/img/church.jpg'
import ballroom from '../assets/img/ballroom.jpg'
import { LocationDetail } from "./LocationDetail";

export const Locations = () => (
  <div className='text-center py-[30px] sm:py-[50px] xl:container mx-auto'>
    <h2 className='text-4xl font-playfairDisplay mb-[50px] font-bold'>Odprawa biletowa i cel podróży</h2>
    <div className='flex flex-col sm:flex-row justify-center gap-10 sm:gap-20'>
      <Card
        imgSrc={church}
        imgAlt='church'
        title='Parafia Nawrócenia św. Pawła'
        subtitle='MSZA ŚWIĘTA I CEREMONIA ZAŚLUBIN'
        text='ul. Wieczorka 3 44-120 Pyskowice'
      >
        <LocationDetail time='14:00' link='https://maps.app.goo.gl/JuGQqJZSRAYULWXR9' />
      </Card>
      <Card
        imgSrc={ballroom}
        imgAlt='ballroom'
        title='Sala bankietowa Stara Szwajcaria'
        subtitle='WESELE I ZABAWA DO BIAŁEGO RANA!'
        text='ul. Łabędzka 6 44-100 Gliwice'
      >
        <LocationDetail time='15:30' link='https://maps.app.goo.gl/PqkgMELMCPxfvki69' />
      </Card>
    </div>
  </div>
)