import gifts from '../assets/img/gifts.png'

export const Gifts = () => (
  <div className='text-center py-[30px] sm:py-[50px] bg-darkRed text-white'>
    <div className='max-w-[90%] mx-auto'>
      <div className='xl:container mx-auto mb-6'>
        <h2 className='text-4xl font-playfairDisplay mb-[50px] font-bold'>Bagaż podręczny</h2>
        <p className='font-quicksand'>Zamiast tradycyjnych kwiatów, prosimy o <span className='font-bold'>bony do sklepów z wyposażeniem wnętrz</span> (np. IKEA, Jysk, Homla), które pomogą nam udekorować nasze nowe gniazdko. Dzięki temu, będziemy mogli stworzyć przestrzeń, w której poczujemy się naprawdę jak w domu.</p>
      </div>
      <div className='max-w-[90%] mx-auto'>
        <img src={gifts} alt="gift icon" className='mx-auto' />
      </div>
    </div>
  </div>
)
