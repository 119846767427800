export const Hello = () => (
  <div className='text-center py-[30px] sm:py-[50px] xl:container mx-auto'>
    <h2 className='text-4xl font-playfairDisplay mb-[50px] font-bold'>Kochani goście - Witajcie!</h2>
    <div className='font-quicksand'>
      <p>Cieszymy się, że jesteście z nami! Chcemy podzielić się wszystkimi szczegółami na temat <span
        className='text-darkRed font-bold'>naszego wielkiego dnia.</span> Znajdziecie tu <span className='font-bold'>najważniejsze adresy, kontakt do nas</span> oraz
        informacje o naszych <span className='font-bold'>usługodawcach</span>. Dodamy także <span className='font-bold'>formularz potwierdzenia obecności, rozkład stołów</span> oraz <span
          className='font-bold'>info o noclegach i transporcie</span>. Koniecznie wracajcie tu raz na jakiś czas, aby
        być na bieżąco!</p>
      <br/>
      <p>Dziękujemy za odwiedziny i <span className='font-bold'>nie możemy się doczekać, aby wspólnie świętować!</span>
      </p>
    </div>
  </div>
)
