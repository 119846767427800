import { Card } from "./Card";
import ola from "../assets/img/ola.jpg";
import { ContactDetails } from "./ContactDetails";
import seweryn from "../assets/img/seweryn.jpg"

export const Team = () => (
  <div className='text-center py-[30px] sm:py-[50px] xl:container mx-auto relative'>
    <h2 className='text-4xl font-playfairDisplay mb-6 sm:mb-12 font-bold'>Nasza drużyna</h2>
    <div className='flex flex-col sm:flex-row justify-center gap-10 sm:gap-20'>
      <Card
        imgSrc={ola}
        imgAlt='ola'
        title='Ola'
        subtitle='świadkowa Heli'
      >
        <ContactDetails
          instagram='https://www.instagram.com/cheshirekoneko?igsh=ZTY4eXJtNm9remVh'
          instagramLabel='Poznaj Olę!'
        />
      </Card>
      <Card
        imgSrc={seweryn}
        imgAlt='seweryn'
        title='Seweryn'
        subtitle='świadek Łukasza'
      >
        <ContactDetails
          instagram='https://www.instagram.com/swadowski/'
          instagramLabel='Poznaj Seweryna!'
        />
      </Card>
    </div>
  </div>
)
