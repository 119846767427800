import { Gifts } from "../components/Gifts";
import { Locations } from "../components/Locations";
import { AboutUs } from "../components/AboutUs";
// import { JoinUs } from "../components/JoinUs";
import { HeroBanner } from "../components/HeroBanner";
import { Counter } from "../components/Counter";
import { Gallery } from "../components/Gallery";
import { Footer } from "../components/Footer";
import { Hello } from "../components/Hello";
import { Team } from "../components/Team";
import { Support } from "../components/Support";
import { Tables } from '../components/Tables';

export const HomePage = () => {
  return (
    <div>
      <HeroBanner />
      <Hello />
      <Counter />
      <AboutUs />
      {/* <JoinUs /> */}
      <Tables />
      <Locations />
      <Gifts />
      <Team />
      <Support />
      <Gallery />
      <Footer />
    </div>
  )
}
